export const lotteryTitle = {
    xsmb: "Miền Bắc",
    xsmt: "Miền Trung",
    xsmn: "Miền Nam",
    xsspl: "Super Rich Lottery",
    hilo: "Hilo",
    "video-poker": "Video Poker",
    keno: "Keno",
    mines: "Mines",
    limbo: "Limbo"
};

export const lotteryType = {
    "1s": "Tức Thì",
};

export const classicTitle = {
    xsmb: "Miền Bắc",
    xsmt: "Miền Trung",
    xsmn: "Miền Nam",
};

export const transformText = {
    "bac-ninh": "Bắc Ninh",
	"ha-noi": "Hà Nội",
	"nam-dinh": "Nam Định",
	"quang-ninh": "Quảng Ninh",
	"hai-phong": "Hải Phòng",
	"thai-binh": "Thái Bình"
};