import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import { Form, Input, Button, DatePicker, Table, Spin, Pagination, Select } from 'antd';
import API, { BOOKMAKER, USER } from '../../network/api';
import moment from 'moment';
import 'moment-timezone';
import { TEXT_DEF } from '../../constant/text';
import ModalCommon from '../../components/Modal';
import { CopyOutlined } from '@ant-design/icons';
import { playerTypes } from '../../constant/playerTypes';
import ModalNickname from "./modalNickname.js"
import { RIGHTS } from "../../constant/rights";
import { hasRight } from "../../components/until/right";
import ModalLevel from "./modalLevel.js";
import { useTabs } from '../../contexts/TabContext';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import { disabledTimeTo, disabledTimeFrom, validateDateRange } from '../../utils/dateUtils.js';

const AccountList = () => {
  let isRequest = useRef(false);
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [totalRecordUser, setTotalRecordUser] = useState(0);
  const locale = {
    emptyText: TEXT_DEF.text0043,
  };
  const [loading, setLoading] = useState(false);
  const [idField, setIdField] = useState();
  const [dataBlock, setDataBlock] = useState();
  const [txtMsg, setTxtMsg] = useState();
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [isShowModalNickname, setIsShowModalNickname] = useState(false);
  const [isShowModalLevel, setIsShowModalLevel] = useState(false);
  const [userSl, setUserSl] = useState();
  const [isReloadPage, setIsRealoadPage] = useState(false);
  const [page, setPage] = useState(1);
  const [params, setParams] = useState('');
  const [bookmarkers, setBookmarkers] = useState([]);
  const [users, setUsers] = useState([]);
  const { addTab, modifyTabContent } = useTabs();
  const [pageSize, setPageSize] = useState(10);
  const location = useLocation();
  const [bookmakerSl, setBookmakerSl] = useState();

  const state = location.state;

  // useEffect(()=> {
  //   if(state) {
  //     const queryString = new URLSearchParams(state).toString();
  //     setParams(queryString)
  //   }
  // },[])

  useEffect(()=> {
    const paramContext = new URLSearchParams(params);
    const queryObbject = {};
    paramContext.forEach((value, key) => {
      queryObbject[key] = value;
    });
    
    modifyTabContent(window.location.pathname, queryObbject)
  }, [params])

  useEffect(() => {
    forceUpdate({});
    getBookmarkers();
  }, []);

  useEffect(() => {
    if(state) {
      const queryString = new URLSearchParams(state).toString();
      form.setFieldValue('dateFrom', dayjs())
      setParams(queryString)
      form.setFieldValue('dateTo', dayjs.unix(state.toDate));
      form.setFieldValue('dateFrom', dayjs.unix(state.fromDate));
      form.setFieldValue('isTestPlayer', state.isTestPlayer);
      form.setFieldValue('nickname', state.nickname);
      form.setFieldValue('username', state.username);
      setPage(parseInt(state.skip ? state.skip : 1))
      setPageSize(state.take? state.take : pageSize)

      if(bookmarkers.length > 0 && state.bookmakerId) {
        const bookmakerA = bookmarkers.find(item => item.key == state.bookmakerId);
        if(bookmakerA) {
          form.setFieldsValue({
            bookmarker: {
              value: bookmakerA.key,
              label: bookmakerA.label
            }
          });
        }
      }
		} else {
      const newDateFrom = dayjs().subtract(3, "months").startOf("day");
      const newDateTo = dayjs();

      form.setFieldValue("dateFrom", newDateFrom);
      form.setFieldValue("dateTo", newDateTo);
      form.setFieldValue("isTestPlayer", "false");

      setDateFrom(newDateFrom);
      setDateTo(newDateTo);

      onChangeDate(newDateFrom, "from");
      onChangeDate(newDateTo, "to");

      getParams();
    }

  }, [bookmarkers])

  useEffect(() => {
    if (params) {
      getUsers();
    }
	}, [params]);

  useEffect(() => {
    if (!isReloadPage) return;

    getUsers();
  }, [isReloadPage]);

  const getParams = (action = '', data = {}) => {
    let params = `skip=${page}`;
    if (action === 'search') {
      setPage(1);
      params = `skip=1&take=${pageSize}`;
    } else if (action === 'change_page') {
      params = `skip=${(data?.page || 1)}&take=${data?.size || pageSize}`;
      setPage(data?.page || 1);
      setPageSize(data?.size);
    } 
    // else if (action === 'export_excel_file') {
    //   params += `&take=100000`;
    // }

    const bookmarker = form.getFieldValue('bookmarker');
    const username = form.getFieldValue('username');
    const nickname = form.getFieldValue('nickname');
    const dateFrom = form.getFieldValue('dateFrom');
    const dateTo = form.getFieldValue('dateTo');
    const isTestPlayer = form.getFieldValue('isTestPlayer');

    
    if (bookmarker) {
      if(typeof bookmarker == 'object') {
        params += `&bookmakerId=${bookmarker.value}`;

      } else {
        params += `&bookmakerId=${bookmarker}`;

      }
    }
    if (username) {
      params += `&username=${username}`;
    }
    if (dateFrom) {
      params += `&fromDate=${dayjs(dateFrom).unix()}`;
    }
    if (nickname) {
      params += `&nickname=${nickname}`;
    }
    if (dateTo) {
      params += `&toDate=${dayjs(dateTo).unix()}`;
    }
    if (isTestPlayer) {
      params += `&isTestPlayer=${isTestPlayer}`;
		}
    setParams(params);

    return params;
  }

  const close = () => {
    setIsShowModalNickname(false);
    setIsShowModalLevel(false);
    setIsRealoadPage(true);
  };

  const getBookmarkers = async () => {
    const result = await API.get(`${BOOKMAKER}/all`);
    const bookmarkers = result.data?.data?.result[0] || [];
    if (!bookmarkers || bookmarkers.length === 0) return;

    const tempBookmarkers = bookmarkers?.map((item) => {
      return {
        key: item?.id,
        value: item?.id,
        label: item?.name,
      };
    });
    setBookmarkers(tempBookmarkers);
  };

  const getUsers = async () => {

    try {
      setLoading(true);
      const response = await API.get(`${USER}/all?${params}`);
      const users = response?.data?.data?.[0] || [];
      const total = response?.data?.data?.[1] || 0;
      const finalResult = [];
      for (const item of users) {
        finalResult.push({
          key: item?.id,
          bookmaker: item?.bookmaker?.name,
          username: item?.username,
          userId: item?.id,
          createdAt: item?.createdAt,
          isBlocked: item?.isBlocked,
          nickname: item?.userInfo?.nickname,
          isTestPlayer: item?.usernameReal === "" ? 'Chơi thật' : 'Chơi thử',
          usernameReal: item?.usernameReal || '',
          level: item?.rank?.rankName,
        });
      }
      setUsers(finalResult);
      setTotalRecordUser(total);
      setLoading(false);
      // setIsRealoadPage(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handlePageChange = (page, size) => {
    getParams('change_page', { page, size });
  };

  const showTotal = (total) => `Tổng cộng ${total} tài khoản`;

  const search = () => {
    getParams('search');
  };

  // const handleShowModal = (id) => {
  //   setIsShowModalConfirm(true);
  //   setIdField(id);
  //   setTxtMsg({
  //     confirm: TEXT_DEF.text0118,
  //     success: TEXT_DEF.text0119,
  //   });
  // };

  const handleShowModalLock = (id, isLock) => {
    setDataBlock({
      id: id,
      isBlocked: isLock,
    });
    setTxtMsg({
      confirm: isLock ? TEXT_DEF.text0120.replace('{}', 'khóa') : TEXT_DEF.text0120.replace('{}', 'mở khóa'),
      success: isLock ? TEXT_DEF.text0121.replace('{}', 'khóa') : TEXT_DEF.text0121.replace('{}', 'mở khóa'),
    });
    setIsShowModalConfirm(true);
  };

  const handleDeleteAccount = async () => {
    try {
      if (isRequest.current) {
        return;
      };

      isRequest.current = true;
      setIsShowModalConfirm(false);
      if (idField) {
        await API.delete(`${USER}/${idField}`);
        setIdField(undefined);
      } else {
        await API.patch(`${USER}/${dataBlock?.id}/block`, { isBlocked: dataBlock?.isBlocked });
        setDataBlock(undefined);
      }
      await getUsers();
      isRequest.current = false;
    } catch (err) {
      console.log(err);
    } finally {
      isRequest.current = false;
      setIsShowModalSuccess(true);
    }
  };

  const showUpdateNickname = (user) => {
    setUserSl(user)
    setIsShowModalNickname(true)
  }

  const showUpdateLevel = (user) => {
    setUserSl(user)
    setIsShowModalLevel(true)
  }

  const goToHistoryWallet = (user) => {
    user.dateFrom = user.createdAt;
    user.dateTo = moment(dateTo).format('YYYY-MM-DD');
    addTab("/userPlayHis", "Lịch sử dòng tiền", { user })
    navigate("/userPlayHis", {state: {user: user}});
  }

  const goToHistoryBet = (user) => {
    user.dateFrom = user.createdAt;
    user.dateTo = moment(dateTo).format('YYYY-MM-DD');
    const state = { user };
    addTab("/report-by-user", "Người chơi", state)

    navigate("/report-by-user", {state: {user: user}});
  }

  const userColums = [
    {
      title: 'STT',
      align: 'center',
      key: 'order',
      render: (text, record, index) => <span>{(page - 1) * pageSize + (index + 1)}</span>,
      fixed: 'left',
      width: 80
    },
    {
      title: TEXT_DEF.text0011,
      dataIndex: 'bookmaker',
      key: 'bookmaker',
      fixed: 'left',
    },
    {
      title: 'Tên HV mã hóa',
      // dataIndex: 'username',
      key: 'username',
      render: (record) =>
        <div className="flex w-full items-center justify-center" >
          <div>{record?.username}</div>
          <CopyOutlined className='copy-icon' onClick={() => copyText(record?.username)} />
        </div>
    },
    {
      title: 'Nickname',
      dataIndex: 'nickname',
      key: 'nickname',
      fixed: 'left',
    },
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      fixed: 'left',
    },
    {
      title: TEXT_DEF.text0148,
      dataIndex: 'isTestPlayer',
      key: 'isTestPlayer',
    },
    {
      title: TEXT_DEF.text0013,
      key: 'createdAt',
      render: (record) => moment(record?.createdAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss'),
    },
    {
      title: TEXT_DEF.text0088,
      key: 'isBlocked',
      render: (record) => (record?.isBlocked ? 'Đã khóa' : 'Không khóa'),
    },
    {
      title: 'Hành động',
      key: 'setting',
      render: (record) =>
      <>
        {
          !record?.usernameReal ? (
            <div className="flex w-full items-center justify-center" style={{ flexDirection: "column" }}>
            {
              hasRight([RIGHTS.ChangeLevelUserPlayGame]) ? (
                <div style={{ cursor: "pointer", color: "#1677ff" }} onClick={() => showUpdateLevel(record)}>Thay đổi level</div>
              ) : null
            }

            {
              hasRight([RIGHTS.BlockUserPlayGame]) ? (
                <div>
                  {!record?.isBlocked ? (
                    <div style={{ cursor: "pointer", color: "#1677ff" }} onClick={() => handleShowModalLock(record?.key, true)}>Khóa</div>
                  ) : (
                    <div style={{ cursor: "pointer", color: "#1677ff" }} onClick={() => handleShowModalLock(record?.key, false)}>Mở khóa</div>
                  )}
                </div>
              ) : null
            }

            {
              hasRight([RIGHTS.ChangeNickNameUserPlayGame]) ? (
                <div style={{ cursor: "pointer", color: "#1677ff" }} onClick={() => showUpdateNickname(record)}>Đổi nickname</div>
              ) : null
            }

            {
              hasRight([RIGHTS.ShowWalletHistory]) ? (
                <div style={{ cursor: "pointer", color: "#1677ff" }} onClick={() => goToHistoryWallet(record)}>Xem lịch sử ví</div>
              ) : null
            }

            {
              hasRight([RIGHTS.ShowReportOrdersByUser]) ? (
                <div style={{ cursor: "pointer", color: "#1677ff" }} onClick={() => goToHistoryBet(record)}>Xem lịch sử cược</div>
              ) : null
            }
          </div>
          ) : null
        }
      </>
    },
  ];

  const copyText = (value) => {
    navigator.clipboard.writeText(value).then(() => {
      alert('Đã sao chép thành công!');
    }).catch(err => {
      console.error('Sao chép thất bại: ', err);
    });
  }

	const onChangeDate = (e, type) => {
    const formattedDate = e ? moment(e.$d).format("YYYY-MM-DD HH:mm:ss") : null;
    if (type === "from") {
      setDateFrom(formattedDate);
    } else {
      setDateTo(formattedDate);
    }
	};
	
  const disabledDateFrom = (current) => {
    if (!dateTo) {
      return current && current > dayjs().endOf('day');
    }
    return current && (current > dayjs().endOf('day') || current < dayjs(dateTo).subtract(3, 'months').startOf('day') || current > dayjs(dateTo).endOf('day'));
  };

  const disabledDateTo = (current) => {
    if (!dateFrom) {
      return current && current > dayjs().endOf('day');
    }
    return current && (current > dayjs().endOf('day') || current < dayjs(dateFrom).startOf('day') || current > dayjs(dateFrom).add(3, 'months').endOf('day'));
  };

  const filterOptionBm = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const handleDownloadExcel = async () => {
    const params = getParams('export_excel_file') + '&take=100000';

    await API.get(`${USER}/all?${params}`)
      .then((response) => {
        const res = response?.data?.data?.[0];
        if (res) {
          let dataCV = [];
          res?.map((item, index) => {
            if (item?.role === 'member') {
              const user = {
                key: index + 1,
                bookmaker: item?.bookmaker?.name,
                username: item?.username ? item?.username : '',
                nickname: item?.userInfo?.nickname ? item?.userInfo?.nickname : '',
                level: item?.rank?.rankName,
                isTestPlayer: item?.usernameReal === "" ? 'Chơi thật' : 'Chơi thử',
                createdAt: moment(item?.createdAt).utcOffset(7).format('DD-MM-YYYY HH:mm:ss'),
                isBlocked: item?.isBlocked == true ? 'khoá' : 'không khoá',
              };
              dataCV.push(user)
            }
          });

          exportToExcel(dataCV)
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const exportToExcel = (data) => {
    const name = "Tài khoản người chơi"

    const formattedData = data.map(item => ({
      'STT': item.key,
      'Nhà điều hành': item.bookmaker,
      'Tên HV mã hoá': item.username,
      'Nickname': item.nickname,
      'Level': item.level,
      'Kiểu tài khoản': item.isTestPlayer,
      'Thời gian đăng ký': item.createdAt,
      'Trạng thái': item.isBlocked,
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${name}.xlsx`);
  };

  const renderAccUser = () => {
    return (
      <>
        <div className="col-12">
          <Form form={form} layout="inline" autoComplete="off" onFinish={() => search()}
            >
            <div className="col-12" style={{ marginBottom: 20, display: 'flex' }}>
              {
                hasRight([RIGHTS.Super]) ? (
                  <div className="col-4">
                    <Form.Item name="bookmarker" label={TEXT_DEF.text0011}
                    rules={[
                      {
                        required: true,
                        message: TEXT_DEF.text0004,
                      },
                    ]}
                    >
                      <Select
                        showSearch
                        placeholder="Chọn nhà cái"
                        optionFilterProp="children"
                        onChange={(val) => setBookmakerSl(val)}
                        filterOption={filterOptionBm}
                        options={bookmarkers}
                        allowClear
                      />
                    </Form.Item>
                  </div>
                ) : null
              }
              <div className="col-4">
                <Form.Item name="username" label={'Tên HV mã hóa'}>
                  <Input style={{ width: '100%' }} />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="nickname" label={'Tên HV'}>
                  <Input style={{ width: '100%' }} />
                </Form.Item>
              </div>
            </div>
            <div className="col-12" style={{ marginBottom: 15, display: 'flex' }}>
              <div className="col-4">
                <Form.Item
                  name="dateFrom"
                  label={TEXT_DEF.text0024}
                  rules={[
                    {
                      required: true,
                      message: TEXT_DEF.text0004,
                    },
                    {
                      validator: () => {
                        return validateDateRange(dateFrom, dateTo);
                      },
                    },
                  ]}
                >
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "from")}
                    format={"DD-MM-YYYY HH:mm:ss"}
                    showTime={{ format: "HH:mm:ss" }}
                    style={{ width: "100%" }}
                    disabledDate={disabledDateFrom}
                    disabledTime={disabledTimeFrom(dateTo)}
                    // inputReadOnly={true}
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item
                  name="dateTo"
                  label={TEXT_DEF.text0025}
                  rules={[
                    {
                      required: true,
                      message: TEXT_DEF.text0004,
                    },
                  ]}
                >
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "to")}
                    format={"DD-MM-YYYY HH:mm:ss"}
                    showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
                    style={{ width: "100%" }}
                    disabledDate={disabledDateTo}
                    disabledTime={disabledTimeTo(dateFrom)}
                    // inputReadOnly={true}
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="isTestPlayer" label={TEXT_DEF.text0148}>
                  <Select showSearch placeholder="" optionFilterProp="children" filterOption={''} options={playerTypes} allowClear />
                </Form.Item>
              </div>
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0026}
              </Button>
              {
                hasRight([RIGHTS.ExportExcelListUsersPlayGame]) ? (
                  <Button
                    style={{ marginLeft: 20 }}
                    onClick={handleDownloadExcel}
                    htmlType="button"
                    type="primary"
                  >
                    {TEXT_DEF.text0027}
                  </Button>
                ) : null
              }
            </div>
          </Form>
        </div>
        {/* <h3>{TEXT_DEF.text0161}</h3> */}
        <Spin spinning={loading} delay={500} size="large" style={{ width: '100%' }}>
          <div style={{ display: 'flex', width: '100%', marginTop: 20 }}>
            <Table
              key="userList"
              locale={locale}
              columns={userColums}
              dataSource={users}
              pagination={false}
              bordered
              style={{ width: '100%' }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              marginTop: 15,
              justifyContent: 'flex-end',
            }}
          >
            <Pagination
              current={page}
              total={totalRecordUser}
              onChange={handlePageChange}
              pageSize={pageSize}
              // defaultPageSize={10}
              // showSizeChanger={false}
              showTotal={showTotal}
              showSizeChanger
              onShowSizeChange={handlePageChange}
              pageSizeOptions={['10', '50', '100', '200', '500', '1000']}
            />
          </div>
        </Spin>
      </>
    );
  };

  return (
    <div className="col-12">
      {renderAccUser()}
      {isShowModalLevel && (
        <ModalLevel
          isShow={isShowModalLevel}
          user={userSl}
          close={close}
          setIsRealoadPage={setIsRealoadPage}
        />
      )}
      {isShowModalNickname && (
        <ModalNickname
          isShow={isShowModalNickname}
          user={userSl}
          close={close}
          setIsRealoadPage={setIsRealoadPage}
        />
      )}
      {isShowModalConfirm && (
        <ModalCommon isShow={isShowModalConfirm} message={txtMsg.confirm} txtBtnAcc={TEXT_DEF.text0008} textBtnClose={TEXT_DEF.text0020} actionNext={handleDeleteAccount} close={() => setIsShowModalConfirm(false)} />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={txtMsg.success}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
          }}
          close={() => {
            setIsShowModalSuccess(false);
          }}
        />
      )}
    </div>
  );
};

export default AccountList;