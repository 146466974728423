import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import SideBarCustom from "../../components/Sidebar";
import HeaderCustom from "../../components/Header";
import { Layout, Menu } from "antd";
import Login from "../login";
import BookmakerList from "../bookmaker/list";
import UpdateBookmaker from "../bookmaker/update";
import CreateBookmaker from "../bookmaker/create";
import AccountList from "../account/list";
import CreateAccount from "../account/create";
import SetPermission from "../account/setPermission";
import LayoutConfig from "../layout/index";
import BxhList from "../bxhFake/list";
import ReportCom from "../report";
import OrderList from "../orders/list";
import WalletHisList from "../walletHis/list";
import WinLossHisList from "../winLossHis/list";
import Dashboard from "../dashboard";
import UserPlayHis from "../userPlayHis";
import UserInfo from "../userInfo";
import PlayHisOriginals from "../playHisOriginals/list";
import LotteryAwardList from "../lotteryAward/index";
import MaintenanceList from "../maintenance";
import SettingGameLottery from "../settingGameLottery";
import StatisticLottery from "../statistic/lottery";
import StatisticOrdersOfUser from "../statisticOrdersOfUser";
import BonusConfig from "../bonus-config";
import BonusConfigCreate from "../bonus-config/create";
import ReportByBookmaker from "../report/bookmaker";
import ReportByGame from "../report/game";
import ReportByOrder from "../report/order";
import ReportByUser from "../report/user";
import ReportByDevice from "../report/device";
import SettingOriginals from "../settingOriginals";
import Rank from "../rank";
import WhiteList from "../whiteListIP";
import RankCreate from "../rank/create";
import QAList from "../listQA";
import GameTextSetting from "../gameText";
import AccountAdminList from "../accAdmin/list";
import CreateAdminUser from "../accAdmin/createUser";
import EditAdminUser from "../accAdmin/edit";
import Role from "../roles";
import RoleCreate from "../roles/create";
import ManagerGame from "../managerGame";
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Link, useLocation } from "react-router-dom";
import { useTabs } from '../../contexts/TabContext';
import ClassicAward from "../classicAward";


const { Sider, Content } = Layout;

const Main = () => {
  const auth = localStorage.getItem("auth");
  const [collapsed, setCollapsed] = useState(false);
  const { openTabs, activeTab, closeTab, changeTab, tabsState } = useTabs();

  const renderPublic = () => {
    return (
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    );
  };

  const renderContent = () => {
    return (
        <Layout>
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            className={`site-body !h-screen !overflow-y-auto ${!collapsed ? '!min-w-[250px]' : '!min-w-[80px]'}`}
            
          >
            <SideBarCustom  />
          </Sider>
          <Layout className="site-layout">
            <Menu mode="horizontal" selectedKeys={[activeTab]}>
              {openTabs.map(tab => (
                <Menu.Item
                  key={tab.key}
                  onClick={() => changeTab(tab.key)}
                  className={`${tab.key === activeTab ? 'active' : null} font-[800]`}
                >
                  <Link 
                  to={tab.key}
                  state={tabsState?.[tab.key] || {}}
                  // state={() => getTabState(tab.key)}
                  // state={getTabState(tab.key)}
                  // state={{ ...tab.state }}
                  // to={{ pathname: tab.key, state: tabsState[tab.key] || {} }}
                  className="uppercase"
                   >{tab.text}</Link>
                  
                  <Button
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                      e.stopPropagation();
                      closeTab(tab.key);
                    }}
                  />
                </Menu.Item>
              ))}
            </Menu>
            <HeaderCustom
              collapsed={collapsed}
              updateCollapsed={() => setCollapsed(!collapsed)}
              username={JSON.parse(auth).username}
            />
            
            <Content
              className="site-layout-background"
              style={{
                margin: "24px 16px",
                padding: 24,
              }}
            >
              <Routes>
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/bookmaker" element={<BookmakerList />} />
                <Route path="/updateBookmaker/:id" element={<UpdateBookmaker />} />
                <Route path="/createBookmaker" element={<CreateBookmaker />} />
                <Route path="/account" element={<AccountList />} />
                <Route path="/userPlayHis" element={<UserPlayHis />} />
                <Route path="/userInfo" element={<UserInfo />} />
                <Route path="/walletHis" element={<WalletHisList />} />
                <Route path="/winLossHis" element={<WinLossHisList />} />
                <Route path="/createAccount" element={<CreateAccount />} />
                <Route path="/orders" element={<OrderList />} />
                <Route path="/setPermission/:id" element={<SetPermission />} />
                <Route path="/hisOriginals" element={<PlayHisOriginals />} />
                <Route path="/lotteryAward" element={<LotteryAwardList />} />
                <Route path="/classicAward" element={<ClassicAward />} />
                <Route path="/report" element={<ReportCom />} />
                {/* <Route path="/setting" element={<SettingSys />} /> */}
                {/* Setting - start */}
                <Route path="/layout" element={<LayoutConfig />} />
                <Route path="/bxhFake" element={<BxhList />} />
                <Route path="/maintenance" element={<MaintenanceList />} />
                <Route path="/setting-lottery-game" element={<SettingGameLottery />} />
                <Route path="/statistic-lottery" element={<StatisticLottery />} />
                <Route path="/statistic-orders" element={<StatisticOrdersOfUser />} />
                <Route path="/bonus-config" element={<BonusConfig />} />
                <Route path="/create-bonus-config" element={<BonusConfigCreate />} />
                <Route path="/edit-bonus-config/:id" element={<BonusConfigCreate />} />
                <Route path="/setting-orginals" element={<SettingOriginals />} />
                <Route path="/list-qa" element={<QAList />} />
                <Route path="/game-text" element={<GameTextSetting />} />
                <Route path="/white-list" element={<WhiteList />} />
                {/* Setting - end */}
                {/* Report - start */}
                <Route path="/report-by-bookmaker" element={<ReportByBookmaker />} />
                <Route path="/report-by-game" element={<ReportByGame />} />
                <Route path="/report-by-device" element={<ReportByDevice />} />
                <Route path="/report-by-user" element={<ReportByUser />} />
                <Route path="/report-by-order" element={<ReportByOrder />} />
                <Route path="/ranks" element={<Rank />} />
                <Route path="/ranks/:id" element={<RankCreate />} />
                {/* Report - end */}
                {/* Manager - start */}
                <Route path="/account-manager" element={<AccountAdminList />} />
                <Route path="/account-manager/create" element={<CreateAdminUser />} />
                <Route path="/account-manager/:id" element={<EditAdminUser />} />
                <Route path="/roles" element={<Role />} />
                <Route path="/roles/create" element={<RoleCreate />} />
                <Route path="/roles/:id" element={<RoleCreate />} />
                {/* Manager - end */}
                {/* Manager game - start */}
                <Route path="/manager-game" element={<ManagerGame />} />
                {/*  Manager game - end */}
              </Routes>
            </Content>
          </Layout>
        </Layout>
    );
  };

  return <>{auth ? renderContent() : renderPublic()}</>;
};

export default Main;
