import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import { Layout } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import logout from "../../image/logout.png";
import ModalCommon from "../Modal";
import { useLocation } from "react-router-dom";
import { TEXT_DEF, TITLE_BONUS_CONFIG, TITLE_BONUS_CONFIG_CREATE } from "../../constant/text";
import moment from "moment";

const { Header } = Layout;

const HeaderCustom = ({ username, collapsed, updateCollapsed }) => {
  const [isShowModal, setIsShowModal] = useState();
  const [titleScreen, setTitleScreen] = useState();
  const location = useLocation();
  const [timeSystem, setTimeSystem] = useState("Loading");


  useEffect(() => {
    if (username) {
      setInterval(() => {
        const timeSys = moment().utcOffset(7).format('DD/MM/YYYY HH:mm:ss');
        const arr = timeSys.split(" ");
        const timeView = `${arr[1]} GMT+7 ${arr[0]}`;
        setTimeSystem(timeView)
      }, 1000);
    }
  })

  useEffect(() => {
    let title;
    let pathName = location.pathname;
    if (
      location.pathname.indexOf("/editAccount/") > -1 ||
      location.pathname.indexOf("/createRankByUser/") > -1
    ) {
      // location.pathname = "/editAccount/";
      pathName = "/editAccount/";
    } else if (location.pathname.indexOf("/setPermission/") > -1) {
      // location.pathname = "/setPermission";
      pathName = "/setPermission";
    } else if (location.pathname.indexOf("/updateBookmaker/") > -1 ||
      location.pathname.indexOf("/createBookmaker") > -1) {
      // location.pathname = "/bookmaker";
      pathName = "/bookmaker";
    } else if (location.pathname.indexOf("/roles/") > -1) {
      // location.pathname = "/editRole";
      pathName = "/editRole";
    }

    switch (pathName) {
      case "/dashboard":
        title = "Trang chủ";
        break;
      case "/bookmaker":
        title = TEXT_DEF.text0010;
        break;
      case "/account":
        title = TEXT_DEF.text0021;
        break;
      case "/createAccount":
        title = TEXT_DEF.text0028;
        break;
      case "/userPlayHis":
        title = TEXT_DEF.text0128;
        break;
      case "/userInfo":
        title = TEXT_DEF.text0135;
        break;
      case "/walletHis":
        title = TEXT_DEF.text0103;
        break;
      case "/winLossHis":
        title = TEXT_DEF.text0111;
        break;
      case "/orders":
        title = TEXT_DEF.text0074;
        break;
      case "/hisOriginals":
        title = TEXT_DEF.text0138;
        break;
      case "/layout":
        title = TEXT_DEF.text0033;
        break;
      case "/bxhFake":
        title = TEXT_DEF.text0040;
        break;
      case "/report":
        title = TEXT_DEF.text0058;
        break;
      case "/setting":
        title = TEXT_DEF.text0059;
        break;
      case "/lotteryAward":
        title = TEXT_DEF.text0146;
        break;
      case "/maintenance":
        title = "Bảo trì hệ thống";
        break;
      case "/setting-lottery-game":
        title = TEXT_DEF.text0150;
        break;
      case "/statistic-lottery":
        title = "Thống kê";
        break;
      case "/statistic-orders":
        title = "Thống kê đơn cược";
        break;
      case "/bonus-config":
        title = TITLE_BONUS_CONFIG;
        break;
      case "/create-bonus-config":
        title = TITLE_BONUS_CONFIG_CREATE;
        break;
      case "/report-by-bookmaker":
        title = "Thống kê theo nhà điều hành";
        break;
      case "/report-by-game":
        title = "Thống kê theo trò chơi";
        break;
      case "/report-by-device":
        title = "Thống kê theo nền tảng";
        break;
      case "/report-by-user":
        title = "Thống kê theo người chơi";
        break;
      case "/report-by-order":
        title = "Thống kê theo đơn cược";
        break;
      case "/setting-orginals":
        title = "Cài đặt originals";
        break;
      case "/list-qa":
        title = "Danh sách Q&A";
        break;
      case "/game-text":
        title = "Text hiển thị";
        break;
      case "/ranks":
        title = "Level";
        break;
      case "/account-manager":
        title = "Danh sách tài khoản quản trị";
        break;
      case "/roles":
        title = "Danh sách vai trò";
        break;
      case "/roles/create":
        title = "Tạo vai trò";
        break;
      case "/editRole":
        title = "Cập nhật vai trò";
        break;
      case "/manager-game":
        title = "QUẢN LÝ TRÒ CHƠI";
        break;
      case "/white-list":
        title = "WHITE LIST";
        break;
      case "/classicAward":
        title = "XỔ SỐ TRUYỀN THỐNG";
        break;

      default:
        title = "";
        break;
    }
    setTitleScreen(title);
  }, [location]);

  return (
    <>
      <Header className="site-layout-background" style={{ padding: 0 }}>
        <div className="header-cus">
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* {collapsed ? (
              <MenuUnfoldOutlined
                className="trigger menu-icon"
                onClick={updateCollapsed}
              />
            ) : (
              <MenuFoldOutlined
                className="trigger menu-icon"
                onClick={updateCollapsed}
              />
            )} */}
            <span style={{ fontSize: 18, fontWeight: 700 }}>{titleScreen?.toUpperCase()}</span>
          </div>

          <div className="header-right">
            <div className="time-system">
              <span>Ngày giờ hệ thống: </span>
              <span style={{ marginLeft: 5 }}>{timeSystem}</span>
            </div>
            <span>{username}</span>
            <img
              className="img-logout"
              src={logout}
              alt="logout"
              onClick={() => setIsShowModal(true)}
            />
          </div>
        </div>
      </Header>
      {isShowModal && (
        <ModalCommon
          isShow={isShowModal}
          message={TEXT_DEF.text0019}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0020}
          actionNext={() => {
            setIsShowModal(false);
            localStorage.clear();
            window.location.assign("/login");
          }}
          close={() => setIsShowModal(false)}
        />
      )}
    </>
  );
};

export default HeaderCustom;
