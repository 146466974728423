export const typeGame = {
  xsmb: 'Xổ Số Miền Bắc',
  xsmt: 'Xổ Số Miền Trung',
  xsmn: 'Xổ Số Miền Nam',
};

export const dataProvinces = [
  {
    all: {
      'ha-noi': 'Hà Nội',
      'quang-ninh': 'Quảng Ninh',
      'bac-ninh': 'Bắc Ninh',
      'hai-phong': 'Hải Phòng',
      'nam-dinh': 'Nam Định',
      'thai-binh': 'Thái Bình',
      'thua-thien-hue': 'TT_Huế',
      'phu-yen': 'Phú Yên',
      'dak-lak': 'Đắk Lắk',
      'quang-nam': 'Quảng Nam',
      'da-nang': 'Đà Nẵng',
      'khanh-hoa': 'Khánh Hoà',
      'binh-dinh': 'Bình Định',
      'quang-binh': 'Quảng Bình',
      'quang-tri': 'Quảng Trị',
      'gia-lai': 'Gia Lai',
      'ninh-thuan': 'Ninh Thuận',
      'quang-ngai': 'Quảng Ngãi',
      'dak-nong': 'Đắk Nông',
      'kon-tum': 'Kon Tum',
      'tp-hcm': 'TP_HCM',
      'dong-thap': 'Đồng Tháp',
      'ca-mau': 'Cà Mau',
      'ben-tre': 'Bến Tre',
      'vung-tau': 'Vũng Tàu',
      'bac-lieu': 'Bạc Liêu',
      'dong-nai': 'Đồng Nai',
      'can-tho': 'Cần Thơ',
      'soc-trang': 'Sóc Trăng',
      'tay-ninh': 'Tây Ninh',
      'an-giang': 'An Giang',
      'binh-thuan': 'Bình Thuận',
      'vinh-long': 'Vĩnh Long',
      'binh-duong': 'Bình Dương',
      'tra-vinh': 'Trà Vinh',
      'long-an': 'Long An',
      'binh-phuoc': 'Bình Phước',
      'hau-giang': 'Hậu Giang',
      'tien-giang': 'Tiền Giang',
      'kien-giang': 'Kiên Giang',
      'da-lat': 'Đà Lạt',
    },
    xsmb: {
      'ha-noi': 'Hà Nội',
      'quang-ninh': 'Quảng Ninh',
      'bac-ninh': 'Bắc Ninh',
      'hai-phong': 'Hải Phòng',
      'nam-dinh': 'Nam Định',
      'thai-binh': 'Thái Bình',
    },
    xsmt: {
      'thua-thien-hue': 'TT_Huế',
      'phu-yen': 'Phú Yên',
      'dak-lak': 'Đắk Lắk',
      'quang-nam': 'Quảng Nam',
      'da-nang': 'Đà Nẵng',
      'khanh-hoa': 'Khánh Hoà',
      'binh-dinh': 'Bình Định',
      'quang-binh': 'Quảng Bình',
      'quang-tri': 'Quảng Trị',
      'gia-lai': 'Gia Lai',
      'ninh-thuan': 'Ninh Thuận',
      'quang-ngai': 'Quảng Ngãi',
      'dak-nong': 'Đắk Nông',
      'kon-tum': 'Kon Tum',
    },
    xsmn: {
      'tp-hcm': 'TP_HCM',
      'dong-thap': 'Đồng Tháp',
      'ca-mau': 'Cà Mau',
      'ben-tre': 'Bến Tre',
      'vung-tau': 'Vũng Tàu',
      'bac-lieu': 'Bạc Liêu',
      'dong-nai': 'Đồng Nai',
      'can-tho': 'Cần Thơ',
      'soc-trang': 'Sóc Trăng',
      'tay-ninh': 'Tây Ninh',
      'an-giang': 'An Giang',
      'binh-thuan': 'Bình Thuận',
      'vinh-long': 'Vĩnh Long',
      'binh-duong': 'Bình Dương',
      'tra-vinh': 'Trà Vinh',
      'long-an': 'Long An',
      'binh-phuoc': 'Bình Phước',
      'hau-giang': 'Hậu Giang',
      'tien-giang': 'Tiền Giang',
      'kien-giang': 'Kiên Giang',
    },
  },
];